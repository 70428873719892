import { call, put, putResolve, select } from 'redux-saga/effects';
import moment from 'moment';
import {
  addParticipantFile,
  addParticipantNote,
  assignLevel,
  getAllowParticipant,
  getAssessmentAnswers,
  getParticipantAssessments,
  getParticipantFiles,
  getParticipantNotes,
  getParticipantOverview,
  getParticipantProgress,
  getParticipantTimeTrackNotes,
  neededAssessments,
} from 'services/api/participant';
import { getWeekDates } from 'helpers/utcHelper';
import {
  createManualAssessment,
  getAssessmentSchedule,
} from 'services/api/assessment';
import { getProgramSessions, getProgramsExtended } from 'services/api/program';
import Swal from 'sweetalert2';
import { ProviderActions } from 'store/provider';
import { getAllDaysOnWeekByDate } from 'helpers/dateHelpers';
import { ParticipantsActions } from 'store/participants';
import { formatAssessmentData } from 'helpers/assessmentHelper';
import {
  ParticipantActions as Actions,
  ParticipantSelectors as Selectors,
  ParticipantTypes as Types,
} from './participantReducer';
import { getPreSelectedCaseByBussinessRule } from 'helpers/caseHelper';

function* fetchParticipant({ participantId }) {
  yield put(Actions.resetParticipant());
  yield put(Actions.setParticipantLoading(true));
  const { currentDate } = yield select(Selectors.getProgressDates);

  try {
    yield call(getAllowParticipant, participantId);
    yield put(Actions.setRedirectToError(false));
  } catch (error) {
    yield put(Actions.setRedirectToError(true));
  }

  try {
    const participant = yield call(getParticipantOverview, participantId);

    const currentCase = getPreSelectedCaseByBussinessRule(participant?.cases);
    const selectedCase = yield select(Selectors.getCaseSelected);
    const urlParams = new URLSearchParams(window.location.search);
    const caseIdfromDeeplink = urlParams.get('caseid');

    if(caseIdfromDeeplink) {
      const participantCasePreSelected = participant?.cases.find(({id}) => Number(caseIdfromDeeplink) === id);
      yield put(Actions.setSelectedCaseSuccess(participantCasePreSelected));
      window.history.replaceState(null, '', window.location.pathname);
    }
    else if(selectedCase) {
      const participantCasePreSelected = participant?.cases.find(({id}) => (selectedCase?.id) === id);
      if(participantCasePreSelected?.name === selectedCase.name){
        yield put(Actions.setSelectedCaseSuccess(participantCasePreSelected));
      }else {
        yield put(Actions.setSelectedCaseSuccess(currentCase));
      }
    }else{
      yield put(Actions.setSelectedCaseSuccess(currentCase));
    }
    
    yield put(Actions.fetchParticipantSuccess(participant));
    yield put(Actions.fetchPrograms(participant.id));
    yield put(Actions.fetchAssessments(participant.id));
    yield put(ProviderActions.fetchChatUrl(participant.id));
    yield put(Actions.fetchNotes(participant.id));
    yield put(Actions.fetchFiles(participant.id));
    yield put(Actions.setCurrentDate(participant.id, currentDate));
  } catch (e) {
    yield put(
      Actions.setParticipantErrors({
        error: e,
        notParticipant: true,
        message: '[getParticipantOverview]: problem getting data',
      })
    );
  } finally {
    yield put(Actions.setParticipantLoading(false));
  }
}

function* refreshParticipant({ participantId }) {
  yield put(Actions.setRefreshParticipant(true));

  try {
    const response = yield call(getParticipantOverview, participantId);

    yield putResolve(
      Actions.fetchParticipantSuccess({
        ...response,
        id: participantId,
      })
    );

    const selectedCase = yield select(Selectors.getCaseSelected);
    const currentCase = response?.cases.find(
      ({ id }) => id === selectedCase.id
    );
    yield put(Actions.setSelectedCaseSuccess(currentCase));
    yield put(ParticipantsActions.updateParticipantsListAfterUpdate());
  } catch (e) {
    yield put(
      Actions.setParticipantErrors({
        error: e,
        notParticipant: true,
        message: '[getParticipantOverview]: problem getting data',
      })
    );
  } finally {
    yield put(Actions.setRefreshParticipant(false));
  }
}

let programController;
function* fetchPrograms({ participantId }) {
  if (programController) {
    programController.abort();
  }

  if (participantId) {
    const selectedCase = yield select(Selectors.getCaseSelected);
    yield put(Actions.setProgramLoading(true));

    try {
      programController = new AbortController();
      const response = yield call(
        getProgramsExtended,
        participantId,
        selectedCase.id,
        programController.signal
      );

      yield put(Actions.fetchProgramsSuccess(response));
      const program = response.find((p) => p.current);

      yield put(Actions.setCurrentProgram(program));
      yield put(Actions.setCurrentLevel(program.currents));
    } catch (e) {
      yield put(Actions.setCurrentProgram(null));
      yield put(Actions.fetchProgramsSuccess([]));
      yield put(
        Actions.setParticipantErrors({
          error: e,
          message: '[getProgramsExtended]: problem getting data',
        })
      );
    } finally {
      yield put(Actions.setProgramLoading(false));
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[getProgramsExtended]: not participant id found',
      })
    );
  }
}

function* setAssignLevel({ level, phase }) {
  const participant = yield select(Selectors.getParticipant);

  if (participant?.id) {
    const selectedCase = yield select(Selectors.getCaseSelected);
    yield put(Actions.setAssingLevelLoading(true));

    try {
      yield call(assignLevel, participant.id, selectedCase.id, level.id);

      Swal.fire({
        title: 'Level changed correctly',
        text: '',
        icon: 'success',
      });
      yield put(
        Actions.setCurrentLevel({
          level,
          phase,
        })
      );
      yield put(Actions.setAssingLevelOk(true));
    } catch (e) {
      yield put(Actions.setPreviousLevel());
      yield put(
        Actions.setParticipantErrors({
          error: e,
          message: '[assignLevel]: problem assigning level',
        })
      );
      Swal.fire({
        title: 'Failed to change the level',
        text: 'Try again later',
        icon: 'error',
      });
    } finally {
      yield put(Actions.setAssingLevelLoading(false));
    }
  }
}

let assessmentsController;
function* fetchAssessments({ participantId }) {
  if (participantId) {
    if (assessmentsController) {
      assessmentsController.abort();
    }

    yield put(Actions.setAssessmentsLoading(true));
    const selectedCase = yield select(Selectors.getCaseSelected);

    try {
      const responseNeeded = yield call(
        neededAssessments,
        participantId,
        selectedCase.id
      );
      const data = {
        needed: responseNeeded.needed,
        url: responseNeeded.needed ? responseNeeded.url : null,
      };

      assessmentsController = new AbortController();
      const { assessments, nextSchedule } = yield call(
        getParticipantAssessments,
        participantId,
        selectedCase.id,
        assessmentsController.signal
      );
      data.data = assessments;
      yield put(Actions.setCanHaveManualAssessment(!data.needed));
      yield put(
        Actions.fetchAssessmentsSuccess(
          formatAssessmentData(data),
          nextSchedule
        )
      );
    } catch (error) {
      yield put(Actions.fetchAssessmentsSuccess(null, null));
      yield put(
        Actions.setParticipantErrors({
          error,
          message: '[getNeedAssessment, getAssessments]: problem getting data',
        })
      );
    } finally {
      yield put(Actions.setAssessmentsLoading(false));
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message:
          '[getNeedAssessment, getAssessments]: not participant id found',
      })
    );
  }
}

function* fetchAssessmentsAnswers({ participantId }) {
  if (participantId) {
    yield put(Actions.setAssessmentAnswersLoading(true));

    try {
      const response = yield call(getAssessmentAnswers, participantId);

      yield put(Actions.fetchAssessmentAnswersSuccess(response));
    } catch (error) {
      yield put(
        Actions.setParticipantErrors({
          error,
          message: '[getAssessmentAnswers]: problem getting data',
        })
      );
    } finally {
      yield put(Actions.setAssessmentAnswersLoading(false));
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[getAssessmentAnswers]: not participant id found',
      })
    );
  }
}

let notesController;
let timetrackControler;
function* fetchNotes({ participantId }) {
  if (notesController) {
    notesController.abort();
  }

  if (timetrackControler) {
    timetrackControler.abort();
  }

  if (participantId) {
    yield put(Actions.setNotesLoading(true));
    const response = {
      notes: [],
      timeTrackerNotes: [],
    };

    const selectedCase = yield select(Selectors.getCaseSelected);

    try {
      notesController = new AbortController();
      const normalNotes = yield call(
        getParticipantNotes,
        participantId,
        selectedCase.id,
        notesController.signal
      );

      response.notes = normalNotes;
    } catch (err) {
      yield put(
        Actions.setParticipantErrors({
          error: err,
          message: '[getParticipantNotes]: error getting notes',
        })
      );
    }

    try {
      timetrackControler = new AbortController();
      const timeTrackerNotes = yield call(
        getParticipantTimeTrackNotes,
        participantId,
        selectedCase.id,
        timetrackControler.signal
      );

      response.timeTrackerNotes = timeTrackerNotes;
    } catch (err) {
      yield put(
        Actions.setParticipantErrors({
          error: err,
          message: '[getParticipantTimeTrackNotes]: error getting notes',
        })
      );
    }

    yield put(Actions.fetchNotesSuccess(response));
    yield put(Actions.setNotesLoading(false));
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message:
          '[getParticipantNotes, getParticipantTimeTrackNotes]: not participant id found',
      })
    );
  }
}

function* setNotes({ note }) {
  yield put(Actions.setNotesModalLoading(true));
  const participant = yield select(Selectors.getParticipant);
  const selectedCase = yield select(Selectors.getCaseSelected);

  if (participant && participant?.id) {
    try {
      yield call(addParticipantNote, {
        ...note,
        user_id: participant.id,
        case_id: selectedCase.id,
      });
      Swal.fire('Added', `The note was saved successfully`, 'success');
      yield put(Actions.setNotesSuccess(true));
      yield put(Actions.fetchNotes(participant.id));
    } catch (err) {
      Swal.fire(
        'Oops!',
        `Limber wasn't able to add the note, please try again`,
        'error'
      );
      yield put(
        Actions.setParticipantErrors({
          error: true,
          message: '[addParticipantNote]: error getting data',
        })
      );
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[addParticipantNote]: not participant id found',
      })
    );
  }
  yield put(Actions.setNotesModalLoading(false));
}

let filesController;
function* fetchFiles({ participantId }) {
  if (filesController) {
    filesController.abort();
  }

  yield put(Actions.fetchFilesSuccess(null));
  const selectedCase = yield select(Selectors.getCaseSelected);
  try {
    yield put(Actions.setFilesLoading(true));

    filesController = new AbortController();
    const response = yield call(
      getParticipantFiles,
      participantId,
      selectedCase.id,
      filesController.signal
    );

    yield put(Actions.fetchFilesSuccess({ data: response }));
  } catch (err) {
    yield put(
      Actions.setParticipantErrors({
        error: err,
        message: '[getParticipantFiles]: error getting files',
      })
    );
  } finally {
    yield put(Actions.setFilesLoading(false));
  }
}

function* fetchProgress({ participantId, date }) {
  if (participantId) {
    const selectedCase = yield select(Selectors.getCaseSelected);
    try {
      yield put(Actions.setProgressLoading(true));
      const [startDate, endDate] = getWeekDates(date);
      const shouldUseUTCImplementation =
        moment(process.env.REACT_APP_IS_UTC_IMPLEMENT).diff(
          startDate,
          'days'
        ) <= 0;

      const response = yield call(
        getParticipantProgress,
        participantId,
        selectedCase.id,
        date
      );

      if (shouldUseUTCImplementation) {
        const { data } = yield call(getProgramSessions, {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          participantId: Number(participantId),
        });

        response.completed_sessions = data
          ? data.map((session) => ({
              date: session.created_at,
              isUtc: true,
            }))
          : [];
      }

      yield put(Actions.fetchProgressSuccess(response));
    } catch (err) {
      yield put(Actions.fetchProgressSuccess(null));
      yield put(
        Actions.setParticipantErrors({
          error: err,
          message: '[getParticipantProgress]: error getting progress',
        })
      );
    } finally {
      yield put(Actions.setProgressLoading(false));
    }
  } else {
    yield put(Actions.setProgressLoading(false));
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[getParticipantProgress]: not participant id found',
      })
    );
  }
}

function* setCurrentDate({ participantId, date }) {
  const dates = getAllDaysOnWeekByDate(date);

  yield put(
    Actions.setCurrentDateSuccess({
      dates,
      currentDate: date,
    })
  );
  yield put(Actions.fetchProgress(participantId, date));
}

function* setFile({ participantId, file }) {
  if (participantId) {
    const selectedCase = yield select(Selectors.getCaseSelected);
    try {
      yield put(Actions.setFilesLoading(true));
      yield call(
        addParticipantFile,
        participantId,
        selectedCase.id,
        file.name,
        file
      );

      Swal.fire({
        title: 'File saved successfully',
        icon: 'success',
      });
      yield put(Actions.fetchFiles(participantId));
    } catch (error) {
      Swal.fire({
        title:
          'Error while trying to save the file, try again in a few minutes',
        icon: 'error',
      });
      yield put(
        Actions.setParticipantErrors({
          error,
          message: '[addParticipantFile]: error getting data',
        })
      );
      yield put(Actions.setFilesLoading(false));
    }
  } else {
    yield put(
      Actions.setParticipantErrors({
        error: true,
        message: '[addParticipantFile]: not participant id found',
      })
    );
  }
}

function* fetchAssessmentsSchedule(action) {
  yield put(Actions.setAssessmentScheduleLoading(true));
  const { participantId } = action;
  const selectedCase = yield select(Selectors.getCaseSelected);

  try {
    const response = yield call(
      getAssessmentSchedule,
      participantId,
      selectedCase.id
    );
    yield put(Actions.fetchAssessmentScheduleSuccess(response.data));
  } catch (error) {
    yield put(
      Actions.setParticipantErrors({
        error,
        message: '[getAssessmentSchedule]: problem getting schedule',
      })
    );
  } finally {
    yield put(Actions.setAssessmentScheduleLoading(false));
  }
}

function* setManualAssessment(action) {
  yield put(Actions.setManualAssessmentLoading(true));
  const { participantId } = action;

  try {
    const selectedCase = yield select(Selectors.getCaseSelected);
    yield call(createManualAssessment, participantId, selectedCase.id);
    Swal.fire('Added', `Reassessment added Successfully`, 'success');
    yield put(Actions.fetchAssessments(participantId));
  } catch (error) {
    Swal.fire('Oops!', `Fail to add Reassessment`, 'error');
    yield put(
      Actions.setParticipantErrors({
        error,
        message: '[createManualAssessment]: problem setting manual',
      })
    );
  } finally {
    yield put(Actions.setManualAssessmentLoading(false));
  }
}

function* setSelectedCase(action) {
  const { caseId } = action;
  const participant = yield select(Selectors.getParticipant);

  const selectedCase = participant.cases.find(({ id }) => id === caseId);

  try {
    yield put(Actions.setSelectedCaseSuccess(selectedCase));
    yield put(Actions.fetchPrograms(participant.id));
    yield put(Actions.fetchAssessments(participant.id));
    yield put(Actions.fetchNotes(participant.id));
    yield put(Actions.fetchFiles(participant.id));
  } catch (error) {
    yield put(
      Actions.setParticipantErrors({
        error,
        message: '[setSelectedCase]: problem setting selected case',
      })
    );
  }
}

export default () => ({
  [Types.FETCH_PARTICIPANT]: fetchParticipant,
  [Types.REFRESH_PARTICIPANT]: refreshParticipant,
  [Types.FETCH_PROGRAMS]: fetchPrograms,
  [Types.SET_ASSIGN_LEVEL]: setAssignLevel,
  [Types.FETCH_ASSESSMENTS]: fetchAssessments,
  [Types.FETCH_ASSESSMENT_ANSWERS]: fetchAssessmentsAnswers,
  [Types.FETCH_NOTES]: fetchNotes,
  [Types.SET_NOTES]: setNotes,
  [Types.FETCH_FILES]: fetchFiles,
  [Types.FETCH_PROGRESS]: fetchProgress,
  [Types.SET_CURRENT_DATE]: setCurrentDate,
  [Types.SET_FILE]: setFile,
  [Types.FETCH_ASSESSMENT_SCHEDULE]: fetchAssessmentsSchedule,
  [Types.SET_MANUAL_ASSESSMENT]: setManualAssessment,
  [Types.SET_SELECTED_CASE]: setSelectedCase,
});
