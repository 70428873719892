import { deleteRequest, getRequest, postRequest, putRequest } from './base';

export const getQuestion = async (questionId) => {
  const url = `questions/${questionId}`;
  return getRequest(url).then((res) => {
    return res.data;
  });
};

export const updateQuestion = async (question) => {
  const url = `questions/${question.id}`;
  return putRequest(url, question).then((res) => {
    return res.data;
  });
};

export const createQuestion = async (question) => {
  const url = `questions`;
  return postRequest(url, question).then((res) => {
    return res.data;
  });
};

export const getAnswer = async (answerId) => {
  const url = `answers/${answerId}`;
  return getRequest(url).then((res) => {
    return res.data;
  });
};

export const updateAnswer = async (answer) => {
  const url = `answers/${answer.id}`;
  return putRequest(url, answer).then((res) => {
    return res.data;
  });
};

export const createAnswer = async (answer) => {
  const url = `answers`;
  return postRequest(url, answer).then((res) => {
    return res.data;
  });
};

export const getAssessment = async (assessmentId) => {
  const url = `assessments/${assessmentId}`;
  return getRequest(url).then((res) => {
    return res.data;
  });
};

export const getAssessmentById = async (assessmentId) => {
  const url = `assessments/byid/${assessmentId}`;
  return getRequest(url).then((res) => {
    return res.data;
  });
};

export const getOrganization = async (organizationId) => {
  const url = `organizations/${organizationId}`;
  return getRequest(url).then((res) => {
    return res.data;
  });
};

export const getOrganizations = async () => {
  const url = `organizations`;
  return getRequest(url).then((res) => {
    return res.data;
  });
};

export const updateAssessment = async (assessment) => {
  const url = `assessments/${assessment.id}`;
  return putRequest(url, assessment).then((res) => {
    return res.data;
  });
};

export const createAssessment = async (assessment) => {
  const url = `assessments`;
  return postRequest(url, assessment).then((res) => {
    return res.data;
  });
};

export const getAssessmentSection = async (id) => {
  const url = `assessmentSections/${id}`;
  return getRequest(url).then((res) => {
    return res.data;
  });
};

export const updateAssessmentSection = async (assessmentSection) => {
  const url = `assessmentSections/${assessmentSection.id}`;
  return putRequest(url, assessmentSection).then((res) => {
    return res.data;
  });
};

export const createAssessmentSection = async (assessmentSection) => {
  const url = `assessmentSections`;
  return postRequest(url, assessmentSection).then((res) => {
    return res.data;
  });
};

export const deleteQuestion = async (questionId) => {
  const url = `questions/${questionId}`;
  return deleteRequest(url).then((res) => {
    return res.data;
  });
};

export const deleteAssessmentSection = async (questionId) => {
  const url = `assessmentSections/${questionId}`;
  return deleteRequest(url).then((res) => {
    return res.data;
  });
};

export const deleteAnswer = async (questionId) => {
  const url = `answers/${questionId}`;
  return deleteRequest(url).then((res) => {
    return res.data;
  });
};

export const getReports = async () => {
  const url = `reportCategories`;
  return getRequest(url).then((res) => {
    return res.data;
  });
};

export const duplicateAssessment = async (id) => {
  const url = `assessments/duplicate`;
  return postRequest(url, { id }).then((res) => {
    return res.data;
  });
};

export const getAssessmentSchedule = async (userId, case_id) =>
  getRequest(`assessment/schedule/${userId}?case_id=${case_id}`);

export const createManualAssessment = async (userId, caseId) =>
  postRequest(`assessments/manual/${userId}?case_id=${caseId}`);
