import moment from 'moment';

export const getQuestionsMaps = (assesmentsData) => {
  let selectedQuestion;

  const questionsArray = assesmentsData.assessmentSections
    .map((assesment) => {
      const { questions } = assesment;
      return questions.map((question) => {
        if (assesment.is_first && question.is_first) {
          selectedQuestion = question;
        }
        return [question.id, question];
      });
    })
    .flat();

  return { questions: Object.fromEntries(questionsArray), selectedQuestion };
};

export const formatAssessmentData = ({ data, needed, url }) => {
  const assessments = data
    .map(
      ({
        id,
        date_completed: dateCompleted,
        risk_value: riskValue,
        risk_category: riskCategory,
        promis_pain: promisPain,
        promis_function: promisFunction,
        created_at: createdAt,
        skipped,
      }) => ({
        id,
        dateCompleted: dateCompleted
          ? `${moment(dateCompleted).format('MM/DD/YYYY')}`
          : 'Not Completed',
        risk:
          riskValue || riskCategory
            ? `${parseFloat(riskValue).toFixed(2)} (${riskCategory})`
            : '-',
        promisPain: promisPain ? parseFloat(promisPain).toFixed(2) : '-',
        promisFunction: promisFunction
          ? parseFloat(promisFunction).toFixed(2)
          : '-',
        key: `assessment-${id}-${createdAt}-${riskValue}`,
        skipped: Boolean(skipped),
      })
    )
    .reverse();

  if (needed) {
    assessments[0] = {
      ...assessments[0],
      finishAssessment: {
        needed,
        url,
      },
    };
  }

  return assessments;
};
