import { takeLatest, takeLeading } from 'redux-saga/effects';
import { getSagasBinds } from 'helpers/sagasHelper';
import { ParticipantTypes as Types } from './participantReducer';
import ParticipantSagas from './participantSagas';

const participantTemplate = {
  data: [
    {
      type: Types.FETCH_PARTICIPANT,
      action: takeLatest,
    },
    {
      type: Types.FETCH_PROGRAMS,
      action: takeLatest,
    },
    {
      type: Types.SET_ASSIGN_LEVEL,
      action: takeLatest,
    },
    {
      type: Types.FETCH_ASSESSMENTS,
      action: takeLatest,
    },
    {
      type: Types.FETCH_NOTES,
      action: takeLatest,
    },
    {
      type: Types.SET_NOTES,
      action: takeLatest,
    },
    {
      type: Types.FETCH_FILES,
      action: takeLatest,
    },
    {
      type: Types.FETCH_PROGRESS,
      action: takeLeading,
    },
    {
      type: Types.SET_CURRENT_DATE,
      action: takeLatest,
    },
    {
      type: Types.SET_FILE,
      action: takeLeading,
    },
    {
      type: Types.REFRESH_PARTICIPANT,
      action: takeLeading,
    },
    {
      type: Types.FETCH_ASSESSMENT_SCHEDULE,
      action: takeLatest,
    },
    {
      type: Types.SET_MANUAL_ASSESSMENT,
      action: takeLatest,
    },
    {
      type: Types.SET_SELECTED_CASE,
      action: takeLatest,
    },
  ],
  sagas: ParticipantSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const participantBinds = getSagasBinds(participantTemplate);
