import { DefaultTenant } from 'constants/defaultValues';

// will add more helpers later
/* eslint-disable import/prefer-default-export */
export function getSelectedTenantId(selectedTenantId, tenantList) {
  if (!selectedTenantId || selectedTenantId === DefaultTenant) {
    return tenantList?.[0]?.id;
  }
  const selectedTenant = tenantList?.find(({ id }) => id === selectedTenantId);
  return selectedTenant?.id;
}
