import axios from 'axios';

export const axiosOutcomesAssessmentInstance = axios.create({
  baseURL: process.env.REACT_APP_OUTCOMES_ASSESSMENT_SERVICE_URL,
});

axiosOutcomesAssessmentInstance.defaults.headers.post['Content-Type'] =
  'application/json;charset=utf-8';
axiosOutcomesAssessmentInstance.defaults.headers.post[
  'Access-Control-Allow-Origin'
] = '*';
axiosOutcomesAssessmentInstance.defaults.headers.common['x-tenant-id'] = 1;

export const getOutcomesAssessmentRequest = (path, config = {}) => {
  return axiosOutcomesAssessmentInstance.get(path, config);
};

export const putOutcomesAssessmentRequest = (path, body) => {
  return axiosOutcomesAssessmentInstance.put(path, body);
};

export const postOutcomesAssessmentRequest = (path, body, options) => {
  return axiosOutcomesAssessmentInstance.post(path, body, options);
};

export const deleteOutcomesAssessmentRequest = (path, body) => {
  return axiosOutcomesAssessmentInstance.delete(path, body);
};
