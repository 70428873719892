import { getSagasBinds } from 'helpers/sagasHelper';
import { takeLatest } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import UserSagas from './userSagas';
import { UserTypes as Types } from './userReducer';

const userTemplate = {
  data: [
    {
      type: Types.SET_CHANGE_PASSWORD,
      action: takeLatest,
    },
    {
      type: Types.SET_CREATE_USER,
      action: takeLatest,
    },
    {
      type: Types.FETCH_ORGANIZATIONS,
      action: takeLatest,
    },
    {
      type: Types.FETCH_USERS,
      action: takeLatest,
    },
    {
      type: Types.FETCH_USER_ROLE,
      action: takeLatest,
    },
    {
      type: Types.SET_LAST_LOGIN,
      action: takeLatest,
    },
    {
      type: Types.SET_DEACTIVATE_USER,
      action: takeLatest,
    },
    {
      type: Types.FETCH_TAGS,
      action: takeLatest,
    },
    {
      type: Types.SET_TENANT,
      action: takeLatest,
    },
    {
      type: Types.FETCH_TENANT_LIST,
      action: takeLatest,
    },
    {
      type: Types.SET_TENANT_SCOPE,
      action: takeLatest,
    },
  ],
  sagas: UserSagas,
};

// eslint-disable-next-line import/prefer-default-export
export const userBinds = getSagasBinds(userTemplate);
